import styled from "styled-components";
import TitleBox from "../../component/TitleBox";
import Button from "../../component/Button";
import {useNavigate} from "react-router-dom";

export default function Privacy() {
    const navigate = useNavigate();
    return (
        <IntroContainer>
            <TitleBox title="개인정보 취급 방침"/>
            <p>국문</p>
            <ScrollBox>
                <h1>개인정보 취급 방침</h1>
                <p>
                    본 개인정보취급방침은 본 방침이 게시된 웹사이트에서 제공되거나 수집되는 정보의 처리에 대해 설명합니다. 또한, 타사 웹사이트 또는 플랫폼에 있는 회사의 애플리케이션을 사용함으로써
                    제공되거나 수집되는 정보의 처리에 대해 설명합니다.
                    회사는 이용자의 개인정보를 중요시하며, 개인정보취급방침을 통하여 회사가 이용자로부터 제공받은 개인정보를 어떠한 용도와 방식으로 이용하고 있으며, 개인정보 보호를 위해 어떠한 조치를
                    취하고 있는지 알려드립니다.
                    본 방침은 2024년 7월 3일부터 시행되며, 이를 개정하는 경우 웹사이트 공지사항(또는 서면, 팩스, 이메일 등의 개별공지)을 통하여 공지하겠습니다.
                </p>
                <h2>1. 수집하는 정보 및 수집방법</h2>
                <h3>(1) 수집하는 개인정보의 항목</h3>
                <p>회사가 수집하는 개인정보의 항목은 다음과 같습니다.</p>
                <ul>
                    <li>이용자가 제공하는 정보
                        <p>회사는 이용자가 직접 제공하는 정보를 수집할 수 있습니다.</p>
                        <p>[개인정보취급방침 부록 &lt;1-1&gt; '개인정보 항목' ]</p>
                    </li>
                    <li>이용자가 서비스를 사용할 때 수집하는 정보
                        <p>회사는 이용자가 직접 제공하는 정보 이외에도, 이용자가 회사의 서비스를 사용하는 과정에서 정보를 수집할 수 있습니다.</p>
                        <p>[개인정보취급방침 부록 &lt;1-2&gt; '개인정보 항목' ]</p>
                    </li>
                </ul>
                <h3>(2) 수집방법</h3>
                <p>회사는 이용자의 정보를 다음과 같은 방법으로 수집합니다.</p>
                <p>[개인정보취급방침 부록 &lt;2&gt; '수집 방법' ]</p>
                <h2>2. 수집한 정보의 이용</h2>
                <p>회사는 수집한 이용자의 정보를 다음과 같은 목적으로 이용합니다.</p>
                <p>[개인정보취급방침 부록 &lt;3&gt; '수집 정보의 이용' ]</p>
                <p>본 개인정보취급방침에 명시된 목적과 다른 용도로 정보를 이용할 경우, 회사는 이용자의 동의를 구하도록 하겠습니다.</p>
                <h2>3. 수집한 정보의 공유</h2>
                <p>회사는 다음의 경우를 제외하고 이용자의 개인정보를 제 3 자에게 공유하지 않습니다.</p>
                <ul>
                    <li>회사의 계열사, 파트너, 서비스 제공업체에 대한 공유
                        <p>[개인정보취급방침 부록 &lt;4-1&gt; '수집 정보의 공유' ]</p>
                    </li>
                    <li>이용자가 사전에 동의하는 경우
                        <p>[개인정보취급방침 부록 &lt;4-2&gt; '수집 정보의 공유' ]</p>
                    </li>
                    <li>법률상 필요한 경우
                        <ul>
                            <li>법령상 공개하도록 요구되는 경우</li>
                            <li>수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                        </ul>
                    </li>
                </ul>
                <h2>4. 쿠키, 비콘 및 기타(Cookies, Beacons and Similar Technologies)</h2>
                <p>
                    회사는 '쿠키(cookies)' 또는 '웹 비콘(web beacons)' 등을 통하여 비개인적인 집합정보를 수집할 수 있습니다.
                    쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로, 이용자의 컴퓨터 하드디스크에 저장됩니다.
                    웹 비콘은 웹사이트 또는 이메일 상에 있는 소량의 코드입니다. 웹 비콘을 사용하여 이용자가 특정 웹이나 이메일 콘텐츠와 상호 작용했는지 여부를 알 수 있습니다.
                    이러한 기능은 서비스를 평가하고 개선하여 이용자 경험을 맞춤 설정하는 데 유용하게 이용되어, 이용자에게 더 향상된 서비스를 제공합니다.
                    회사가 수집하는 쿠키의 항목 및 수집 목적은 다음과 같습니다.
                    [개인정보취급방침 부록 &lt;5&gt; '수집하는 쿠키' ]
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의
                    저장을 거부할 수도 있습니다. 단, 이용자께서 쿠키 설치를 거부하였을 경우 회사가 제공하는 일부 서비스에 어려움이 있을 수 있습니다.
                </p>
                <h2>5. 이용자의 접근권한과 선택권</h2>
                <p>이용자 또는 법정대리인은 정보의 주체로서 회사의 개인정보 수집, 사용, 공유와 관련하여 다음과 같은 선택권을 행사할 수 있습니다.</p>
                <ul>
                    <li>개인정보에 대한 접근권한</li>
                    <li>개인정보의 정정 또는 삭제</li>
                    <li>개인정보 처리의 일시 정지</li>
                    <li>기존에 제공한 동의의 철회의 요청</li>
                </ul>
                <p>
                    이를 위하여 웹페이지의 '회원정보 수정' 메뉴를 이용하시거나, 대표전화 또는 회사 담당부서(또는 개인정보 관리책임자)에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
                    조치하겠습니다. 다만 회사는 법률에 명시된 타당한 사유 또는 그에 상응하는 사유가 있는 경우에만 그러한 요청을 거부할 수 있습니다.
                </p>
                <h2>6. 보안</h2>
                <p>회사는 이용자의 개인정보에 대한 보안을 매우 중요하게 생각합니다. 회사는 이용자 개인정보의 무단 접근, 공개, 사용 및 수정을 막기 위해 다음과 같은 보안 조치를 구축하고
                    있습니다.</p>
                <p>[개인정보취급방침 부록 &lt;6&gt; '보안조치' ]</p>
                <h2>7. 어린이 개인정보 보호</h2>
                <p>
                    회사는 원칙적으로 13 세 미만 또는 관할 법률상 이에 상응하는 최소 연령의 어린이로부터 정보를 수집하지 않습니다. 회사의 웹사이트, 제품과 서비스 등은 원칙적으로 일반인을
                    대상으로 한 웹사이트입니다. 회사의 웹사이트 또는 애플리케이션에는 연령 제한 기능이 있어서 어린이가 이용할 수 없도록 하고 있으며, 그러한 기능을 통해 어린이로부터 고의로
                    개인정보를 수집하지 않습니다.
                    (어린이 개인정보를 수집하는 경우 추가) 다만 회사가 부득이 서비스 이용을 위하여 13 세 미만 또는 관할 법률상 이에 상응하는 최소 연령의 어린이의 개인정보를 수집할 때에는,
                    어린이 개인정보 보호를 위해 다음과 같은 절차를 추가적으로 거치게 됩니다.
                </p>
                <ul>
                    <li>어린이 개인정보 수집 또는 회사의 제품, 서비스 정보를 어린이에게 직접 발송하기 위한 보호자 동의 획득</li>
                    <li>수집한 개인정보 항목, 목적, 공유 여부를 포함한 회사의 어린이 개인정보보호 방침에 대하여 보호자에게 통보</li>
                    <li>법정대리인에게 해당 아동의 개인정보에 대한 액세스 / 개인정보의 정정 또는 삭제 / 개인정보 처리의 일시 정지 / 기존에 제공한 동의의 철회를 요청할 수 있는 권한의
                        부여
                    </li>
                    <li>온라인 활동의 참여에 필요한 것 이상의 개인정보 수집의 제한</li>
                </ul>
                <h2>8. 개인정보취급방침의 변경</h2>
                <p>회사는 회사의 본 방침을 수시로 수정 내지 변경할 권리를 보유합니다. 회사가 본 방침을 변경하는 경우 웹사이트 공지사항(또는 서면, 팩스, 이메일 등의 개별공지)을 통하여 공지하며,
                    관계법에서 요구하는 경우에는 이용자의 동의를 구하게 됩니다.</p>
                <h2>9. 기타</h2>
                <p>[개인정보취급방침 부록 &lt;7&gt; '데이터 전송' ]</p>
                <p>[개인정보취급방침 부록 &lt;8&gt; '제 3 자 사이트 및 서비스' ]</p>
                <p>[개인정보취급방침 부록 &lt;9&gt; '캘리포니아 거주자에 대한 안내' ]</p>
                <p>[개인정보취급방침 부록 &lt;10&gt; '한국인 거주자에 대한 안내' ]</p>
                <h2>10. 회사 담당부서</h2>
                <p>
                    회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서를 지정하고 있습니다. 본 방침에 대하여 의문 사항 있거나 회사가 보유한 이용자의
                    정보를 업데이트하고자 하는 경우, 아래 연락처로 회사에 연락하시기 바랍니다.
                </p>
                <ul>
                    <li>담당자 : 사장 이재진</li>
                    <li>주소 : 대전 유성구 대학로155번길 29 2층</li>
                    <li>전화 : (+82)10-4186-6219</li>
                    <li>이메일 : 2jaejin21@gmail.com</li>
                </ul>
                <p>최종갱신일 2024년 7월 3일</p>
            </ScrollBox>
            <p>영문</p>
            <ScrollBox>
                <p>
                    This Privacy Policy (the "Policy") explains the way of treatment of the information which is
                    provided or collected in the websites on which this Policy is posted. In addition, the Policy also
                    explains the information which is provided or collected in the course of using the applications
                    of the Company which exist in the websites or platforms of other company.
                    Through this Policy, the Company regards personal information of the users as important and
                    inform them of the purpose and method of Company's using the personal information
                    provided by the users and the measures taken by the Company for protection of those personal
                    information.
                    This Policy will be effective on the 3rd day of JULY, 2024 and, in case of modification thereof,
                    the Company will make public notice of it through posting it on the bulletin board of
                    Company's website or individual notice through sending mails, fax or e-mails.
                </p>
                <h2>1. Information to be collected and method of collection</h2>
                <h3>(1) Personal information items to be collected</h3>
                <p>
                    Personal information items to be collected by the Company are as follows:
                </p>
                <ul>
                    <li>Information provided by the users
                        <p>The Company may collect the information directly provided by the users.</p>
                        <p>[Option to select 'personal information items' in Appendix &lt;1-1&gt; of Personal Privacy
                            Policy]</p>
                    </li>
                    <li>Information collected while the users use services
                        <p>Besides of information directly provided by the users, the Company may collect information in
                            the course that the users use the service provided by the Company.</p>
                        <p>[Option to select 'personal information items' in Appendix &lt;1-2&gt; of Personal Privacy
                            Policy]</p>
                    </li>
                </ul>
                <h3>(2) Method of collection</h3>
                <p>
                    The Company collects the information of users in a way of the followings:
                    [Option to select 'method of collection' in Appendix &lt;2&gt;of Personal Privacy Policy]
                </p>
                <h2>2. Use of collected information</h2>
                <p>
                    The Company uses the collected information of users for the following purposes:
                    [Option to select 'use of collected information' in Appendix &lt;3&gt;of Personal Privacy Policy]
                    The Company agrees that it will obtain consent from the users, if the Company desires to use
                    the information other than those expressly stated in this Policy.
                </p>
                <h2>3. Sharing collected information</h2>
                <p>
                    Except for the following cases, the Company will not share personal information with a 3rd
                    party:
                </p>
                <ul>
                    <li>when the Company shares the information with its affiliates, partners and service providers;
                        [Option to select 'sharing of collected information' in Appendix &lt;4-1&gt;of Personal Privacy
                        Policy]
                    </li>
                    <li>when the users consent the sharing in advance;
                        [Option to select 'sharing of collected information' in Appendix &lt;4-2&gt;of Personal Privacy
                        Policy]
                    </li>
                    <li>when the sharing is required by the laws
                        <ul>
                            <li>if required to be disclosed by the laws and regulations; or</li>
                            <li>if required to be disclosed by the investigative agencies for detecting crimes in
                                accordance with the procedure and method as prescribed in the laws and regulations
                            </li>
                        </ul>
                    </li>
                </ul>
                <h2>4. Cookies, Beacons and Similar Technologies</h2>
                <p>
                    The Company may collect collective and impersonal information through 'cookies' or 'web
                    beacons'.
                    Cookies are very small text files to be sent to the browser of the users by the server used for
                    operation of the websites of the Company and will be stored in hard-disks of the users'
                    computer.
                    Web beacon is a small quantity of code which exists on the websites and e-mails. By using web
                    beacons, we may know whether a user has interacted with certain webs or the contents of
                    email.
                    These functions are used for evaluating, improving services and setting-up users' experiences so
                    that much improved services can be provided by the Company to the users
                    The items of cookies to be collected by the Company and the purpose of such collection are as
                    follows:
                    [Option to select 'collecting cookies' in Appendix &lt;5&gt;of Personal Privacy Policy]
                    The users have an option for cookie installation. So, they may either allow all cookies by setting
                    option in web browser, make each cookie checked whenever it is saved, or refuses all cookies to
                    be saved: Provided that, if the user rejects the installation of cookies, it may be difficult for
                    that
                    user to use the parts of services provided by the Company.
                </p>
                <h2>5. Users' right to access and option</h2>
                <p>
                    The users or their legal representatives, as main agents of the information, may exercise the
                    following options regarding the collection, use and sharing of personal information by the
                    Company:
                </p>
                <ul>
                    <li>exercise right to access to personal information;</li>
                    <li>make corrections or deletion;</li>
                    <li>make temporary suspension of treatment of personal information; or</li>
                    <li>request the withdrawal of their consent provided before</li>
                </ul>
                <p>
                    If, in order to exercise the above options, you, as an user, use the menu of 'amendment of
                    member information of webpage or contact the Company by using representative telephone or
                    sending a document or e-mails, or using telephone to the responsible department (or person in
                    charge of management of personal information), the Company will take measures without
                    delay: Provided that the Company may reject the request of you only to the extent that there
                    exists either proper cause as prescribed in the laws or equivalent cause.
                </p>
                <h2>6. Security</h2>
                <p>
                    The Company regards the security of personal information of uses as very important. The
                    company constructs the following security measures to protect the users' personal information
                    from any unauthorized access, release, use or modification
                    [Option to select 'security measures' in Appendix &lt;6&gt;of Personal Privacy Policy]
                </p>
                <h2>7. Protection of personal information of children</h2>
                <p>
                    In principle, the Company does not collect any information from the children under 13 or
                    equivalent minimum age as prescribed in the laws in relevant jurisdiction. The website, products
                    and services of the Company are the ones to be provided to ordinary people, in principle. The
                    website or application of the Company has function to do age limit so that children cannot use
                    it and the Company does not intentionally collect any personal information from children
                    through that function.
                </p>
                <p>
                    (Additional procedure for collecting personal information from children) However, if the
                    Company collects any personal information from children under 13 or equivalent minimum age
                    as prescribed in the laws in relevant jurisdiction for the services for unavoidable reason, the
                    Company will go through the additional procedure of the followings for protecting that
                    personal information of children:
                </p>
                <ul>
                    <li>obtain consent from the parents or guardian of children so as to collect personal information
                        of children or directly send the information of products and services of the Company
                    </li>
                    <li>give the parents or guardian of children a notice of Company's policy of privacy protection
                        for children including the items, purpose and sharing of personal information collected
                    </li>
                    <li>grant to legal representatives of children a right to access to personal information of that
                        children/correction or deletion of personal information/temporary suspension of treatment of
                        personal information/ and request for withdrawal of their consent provided before
                    </li>
                    <li>limit the amount of personal information exceeding those necessary for participation in
                        online activities
                    </li>
                </ul>
                <h2>8. Modification of Privacy Protection Policy</h2>
                <p>
                    The Company has the right to amend or modify this Policy from time to time and, in such case,
                    the Company will make a public notice of it through bulletin board of its website (or through
                    individual notice such as written document, fax or e-mail) and obtain consent from the users if
                    required by relevant laws.
                </p>
                <h2>9. Others</h2>
                <p>
                    [Option to select 'data transmission' in Appendix &lt;7&gt; of Personal Privacy Policy]
                    [Option to select 'sites and service of 3rd party' in Appendix &lt;8&gt; of Personal Privacy Policy]
                    [Option to select 'guidelines for residents in California' in Appendix &lt;9&gt; of Personal Privacy
                    Policy]
                    [Option to select 'guidelines for residents in Korea' in Appendix &lt;10&gt; of Personal Privacy
                    Policy]
                </p>
                <h2>10. Responsible department of Company</h2>
                <p>
                    The Company designates the following department and person in charge of personal
                    information in order to protect personal information of customers and deal with complaints
                    from customers
                </p>
                <ul>
                    <li>Address : 29, Daehak-ro 155beon-gil, Yuseong-gu, Daejeon, Republic of Korea</li>
                    <li>Tel.: (+82)10-4186-6219</li>
                    <li>E-mail: 2jaejin21@gmail.com</li>
                </ul>
                <p>
                    The latest update date: 03 July, 2024
                </p>
            </ScrollBox>
            <Button action={()=>{navigate("/mypage")}} value={"뒤로가기"}/>
        </IntroContainer>
    )
};
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const ScrollBox = styled.div`
    height: 300px;
    overflow-y: scroll;
    background-color: white;
`