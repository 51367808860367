import styled from 'styled-components'

export default function Button(props) {
    const {type, action, value, disable} = props
    const color = ()=>{
        switch (type){
            case 'primary':
                return '#3F51B5'
            default:
                return '#37474F'
        }
    }
    const colorhover = ()=>{
        switch (type){
            case 'primary':
                return '#283593'
            default:
                return '#607D8B'
        }
    }

    return <Btn disabled={disable} onClick={action}
                color={color(type)} colorhover={colorhover(type)}>
        {value}
    </Btn>
};

const Btn = styled.button`
    border: none;
    margin: 6px 10px;
    border-radius: 5px;
    background-color: ${(props) => props.color ? props.color : '#34353e'};
    width: 200px;
    font-size: 15px;
    color: white;
    padding: 5px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
        background-color: ${(props) => props.colorhover ? props.colorhover : '#636a78'};;
    }

    &:disabled {
        background-color: transparent;
        border: 1px solid #bababa;
        cursor: not-allowed;
        color: #bababa;
    }
`