import styled from 'styled-components'

export default function ShortCard(props) {
    const {machine, status} = props

    return <> {status === "pending" || status === "approved" ?<Card className={"card"}>
        <ColL>
            <DescText>{machine}</DescText>
        </ColL>
        <ColR>
            <DescText>
                {(() => {
                    switch (status) {
                        case "pending":
                            return <Badge style={{backgroundColor: "#3498DB"}}>요청중</Badge>;
                        case "approved":
                            return <Badge style={{backgroundColor: "#27AE60"}}>확정</Badge>;
                        default:
                            return <Badge style={{backgroundColor: "#E67E22"}}>예약불가</Badge>;
                    }
                })()}
            </DescText>
        </ColR>
    </Card> : null}
    </>
};
const Card = styled.div`
    border-radius: 5px;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid #9ba2b1;
    margin-bottom: 10px;
    text-align: start;
    display: flex;
`
const ColL = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: start;
    justify-content: center;
`
const ColR = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: end;
    justify-content: center;
`
const DescText = styled.div`
    font-size: 14px;
    color: #252f3f;
    padding: 0;
    font-weight: bolder;
`
const Badge = styled.div`
    text-align: center;
    color: white;
    margin-left: auto;
    border: none;
    width: 90px;
    padding: 2px;
    border-radius: 5px;
    text-decoration: none;
    transition-duration: 0.2s;
`