import styled from "styled-components";
import {redirect, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import customAxios from "../../libs/customAxios";


export default function Auth(){
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    useEffect( () => {
        const url = '/auth/auth_backend';
        customAxios.get(url, {"params":{
                "code": code
            }})
            .then((response) => {
                if(response.data.access_token){
                    customAxios.get("/auth/auth_withtoken", {
                        "params":{
                            "token":response.data.access_token
                        }
                    }).then((res)=>{
                        sessionStorage.setItem("token", res.data.access_token);
                        if(res.data.is_registered){
                            window.location = '/'
                        }else{
                            window.location = '/onboarding'
                        }

                    })
                }
            })
            .catch(error => {
                redirect("/")
            });
    }, []);
    return (
        <IntroContainer>
            <DescContainer>
                <MainText>잠시 기다려주세요</MainText>
            </DescContainer>
        </IntroContainer>
    )
}
const MainText = styled.div`
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #2e2e2e;
`
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const DescContainer = styled.div`
    font-size: 16px;
    min-height: 200px;
    align-content: center;
    color: #8793a6;
    margin: 10px;
    width: 100%;
    text-align: center;
`