import styled from "styled-components";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function Logout(){
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => navigate("/"), 2000);
    }, []);
    return (
        <IntroContainer>
            <DescContainer>
                <MainText>로그아웃 되었습니다</MainText>
                <p>3초뒤 자동으로 홈으로 이동합니다</p>
            </DescContainer>
        </IntroContainer>
    )
}

const MainText = styled.div`
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #2e2e2e;
`
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const DescContainer = styled.div`
    font-size: 16px;
    min-height: 200px;
    align-content: center;
    color: #8793a6;
    margin: 10px;
    width: 100%;
    text-align: center;
`