import axios from 'axios';

const customAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    withCredentials: true,
});

customAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            sessionStorage.clear();
            window.location = "/logout"
        }
        window.location = "/"
        return Promise.reject(error);
    }
);

export default customAxios;