import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";


export default function Onboarding(){
    const navigate = useNavigate();
    const [reqform, setReqform] = useState({
        nickname: null,
        phone: '',
        description: '',
    });
    const reqchanged = (e) => {
        if(e.target.name === "phone"){
            const regex = /^[0-9\b -]{0,13}$/;
            if (regex.test(e.target.value)) {
                setReqform({...reqform, [e.target.name]:e.target.value});
            }
        }else{
            setReqform({...reqform, [e.target.name]:e.target.value});
        }
    }
    const onSubmit = async(e) => {
        e.preventDefault();
        if(!reqform.nickname || reqform.phone.length < 13){
            alert('빈 항목이 있습니다');
            return;
        } else{
            customAxios.post('/user/onboarding', {
                "nickname": reqform.nickname,
                "phone": reqform.phone,
                "detail": reqform.description,
            }).then((res)=>{
                if (res.status === 200) {
                    navigate("/")
                }
            })
        }
    }

    useEffect(() => {
        const newphone = reqform.phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        setReqform({...reqform, phone:newphone});
    }, [reqform.phone]);
    return (
        <IntroContainer>
            <TitleContainer>
                <TitleImage src={"images/logo_dark.png"} alt="logo"/>
                <TitleText>추가 정보 입력</TitleText>
            </TitleContainer>
            <HLine/>
            <DescContainer>
                <Label>이름 (관리자만 조회가능)</Label>
                <Input placeholder={"3글자 이상 이름"} maxLength={16} value={reqform.nickname} name={"nickname"} onChange={reqchanged}/>
                <Label>연락처*</Label>
                <Input placeholder={"010-0000-0000 숫자만 입력"} maxLength={14} type={"phone"} value={reqform.phone} name={"phone"} onChange={reqchanged}/>
                <Label>짧은소개</Label>
                <Input placeholder={"잘부탁드립니다"} maxLength={120} value={reqform.description} name={"description"} onChange={reqchanged}/>
            </DescContainer>
            <RequestBtn disabled={reqform.phone.length<13 || reqform.nickname.length<3} type={"submit"} onClick={onSubmit}>회원가입</RequestBtn>
        </IntroContainer>
    )
}
const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
`
const TitleImage = styled.img`
    position: sticky;
    top: 0;
    width: 100px;
    filter: grayscale(0.5);
`
const HLine = styled.hr`
    width: 100%;
    max-width: 500px;
`
const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const TitleText = styled.h1`
    font-weight: bold;
    font-size: 24px;
    color: #252f3f;
    padding: 0;
    margin: 0 0 0 10px;
`
const DescContainer = styled.div`
    font-size: 16px;
    min-height: 200px;
    align-content: center;
    color: #8793a6;
    margin: 10px;
`
const Label = styled.div`
    width: 100%;
    color: #252f3f;
    padding: 10px;
    font-size: 14px;
    text-align: start;
`
const Input = styled.input`
    width: 100%;
    height: 30px;
    border: 1px solid #9ba2b1;
    text-align: center;
    border-radius: 5px;
`

const RequestBtn = styled.button`
    border: none;
    margin: 10px;
    border-radius: 5px;
    background-color: #612bea;
    width: 150px;
    font-size: 15px;
    color: white;
    padding: 5px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
        background-color: #4800ff;
    }

    &:disabled {
        background-color: #a9b1c1;
        cursor: default;
    }
`