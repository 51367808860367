import styled from 'styled-components'

export default function TitleBox(props) {
    const {title} = props
    return <div>
        <TitleContainer>
            <TitleImage src={"images/logo_dark.png"} alt="logo"/>
            <TitleText>{title}</TitleText>
        </TitleContainer>
        <HLine/>
    </div>
};

const TitleContainer = styled.div`
    display: flex;
    margin-top: 20px;
`
const TitleImage = styled.img`
    position: sticky;
    top: 0;
    height: 36px;
    filter: grayscale(0.3);
    margin: 0 10px;
`
const TitleText = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: #252f3f;
    padding: 0;
`
const HLine = styled.hr`
    width: 100%;
    max-width: 500px;
    padding: 0;
`