import styled from "styled-components";
import {useEffect, useState} from "react";
import customAxios from "../../libs/customAxios";
import {useNavigate} from "react-router-dom";
import TitleBox from "../../component/TitleBox";
import Button from "../../component/Button";

export default function Myres(){
    const navigate = useNavigate();
    const [reservations, setReservations] = useState([])

    const reqcancle = (id) => {
        const userConfirmed = window.confirm("정말로 해당 예약요청을 취소하시겠습니까?");
        if (userConfirmed) {
            customAxios.delete('/reserve/cancle', {
                params: {id: id}
            }).then((res) => {
                if (res.data.status === "success") {
                    window.location.reload();
                }
            })
        }
    }

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if(token){
            customAxios.get('/reserve/myres').then((res)=>{
                if(res.data.status === "success"){
                    setReservations(res.data.data)
                }
            })
        } else {
            navigate('/');
        }
    }, []);

    return (
        <IntroContainer>
            <TitleBox title={"나의 예약"}/>
            <Content>
                {reservations.length > 0 ? reservations.map((item, index) => (
                        <Card className={"card"}>
                            <ColL>
                                <MachineTitle>{item.machine_type}</MachineTitle>
                                <DescText>{item.detail}</DescText>
                                <DescText>{item.date} - {item.reserve_type}</DescText>
                                <DescText>{item.option}</DescText>
                            </ColL>
                            <ColR>
                                <DescText>
                                    {(() => {
                                        switch (item.type) {
                                            case "pending":
                                                return <Badge style={{backgroundColor: "#3498DB"}}>요청중</Badge>;
                                            case "approved":
                                                return <Badge style={{backgroundColor: "#27AE60"}}>확정</Badge>;
                                            default:
                                                return <Badge style={{backgroundColor: "#E67E22"}}>예약불가</Badge>;
                                        }
                                    })()}
                                </DescText>
                                <DescText>
                                    {item.type=== "pending"
                                        ? <CancleBtn onClick={()=>reqcancle(item.reserve_id)}>취소</CancleBtn>
                                        :<></>
                                    }
                                </DescText>
                            </ColR>
                        </Card>
                    ))
                    : <p>내역없음</p>
                }
                <p>과거 예약 내역은 보여지지 않습니다</p>
            </Content>
            <Button type={"primary"}
                    action={()=>{navigate("/res")}} value={"신규예약"}/>
            <Button action={()=>{navigate("/")}} value={"뒤로가기"}/>
        </IntroContainer>
    )
}

const IntroContainer = styled.div`
    width: 100%;
    font-weight: bold;
`
const MachineTitle = styled.h1`
    font-size: 22px;
    color: #252f3f;
    padding: 0;
    margin: 0;
    font-weight: bolder;
`
const DescText = styled.div`
    font-size: 14px;
    color: #252f3f;
    padding: 0;
    font-weight: bolder;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    font-size: 16px;
    color: #8793a6;
    padding: 10px;
`
const Card = styled.div`
    padding: 10px 20px;
    background-color: white;
    border: 1px solid #9ba2b1;
    margin-bottom: 10px;
    text-align: start;
    display: flex;
    justify-content: center;
`
const ColL = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: start;
    justify-content: center;
`
const ColR = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: end;
    justify-content: center;
`
const Badge = styled.div`
    text-align: center;
    color: white;
    margin-left: auto;
    border: none;
    width: 90px;
    padding: 2px;
    border-radius: 5px;
    text-decoration: none;
    transition-duration: 0.2s;
`
const CancleBtn = styled.button`
    text-align: center;
    margin-top: 30px;
    padding: 0 20px;
    background-color: #535151;
    color: white;
    border: none;
    height: 30px;
    border-radius: 5px;
    text-decoration: none;
    transition-duration: 0.2s;
    &:hover {
        background-color: #C0392B;
    }
`