import styled from 'styled-components'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Introduction from "./pages/mainpage/introduction";
import Reservation from "./pages/reservation/reservation";
import Auth from "./pages/auth/auth";
import Onboarding from "./pages/auth/onboarding";
import Logout from "./pages/auth/logout";
import Mypage from "./pages/user/mypage";
import Myres from "./pages/reservation/myres";
import Checkres from "./pages/reservation/checkres";
import Privacy from "./pages/user/privacy";

function App() {
  return (
    <AppContainer>
      <BrowserRouter>
          <Routes>
              <Route path="res" element={<Reservation />} />
              <Route path="logout" element={<Logout />} />
              <Route path="mypage" element={<Mypage />} />
              <Route path="meinkampf" element={<Myres />} />
              <Route path="auth" element={<Auth />} />
              <Route path="onboarding" element={<Onboarding />} />
              <Route path="schedule" element={<Checkres />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="" element={<Introduction />} />
          </Routes>
      </BrowserRouter>
    </AppContainer>
  );
}

const AppContainer = styled.div`
    width: 80vw;
    max-width: 500px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    "Nanum Gothic", sans-serif;
`

export default App;
